
import React, { useState, useEffect, useRef, PropsWithChildren } from 'react';
export interface DropdownProps {
  header: string;
  menu: string[];
  selectionHandler?: any //TODO: replace with explicit type
}

export function Dropdown(props: PropsWithChildren<DropdownProps>) {

  const [open, setDropdownOpen] = useState(false)

  let menuRef = useRef<any>(null);

  useEffect(() => {
    let handler = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        setDropdownOpen(false)
      }
    }
    document.addEventListener('mousedown', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  const handleOpen = () => {
    setDropdownOpen(!open)
  }

  const handleSelection = (item: string) => {
    props.selectionHandler?.(item);
    setDropdownOpen(false);
  };

  return (
    <div className='dropdown'>
      <button className='btn btn-outline-secondary' onClick={handleOpen} ref={menuRef}>
        {open ? (
          <ul className="dropdown-menu show">
            {props.menu.map((menuItem, index) => (
              <li key={menuItem} className="dropdown-item text-dark" onClick={() => handleSelection(menuItem)}>
                {menuItem}
              </li>
            ))}
          </ul>
        ) : (
          <div>{props.header}</div>
        )}
      </button>
    </div>
  );
}