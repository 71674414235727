
import { Config } from "./typing";


export const config: Config = {
  basename: '/',
  oauthClient: {
    domain: 'https://login.odkey.de',
    clientId: 'KWmQ6RduhzaKiURtsE4jNyEDIS62z9W1',
    audience: 'https://odkey.de/api',
  }
}
